//data
function data() {
  return {
    startAngle: 0,
    center: {
      x: 0,
      y: 0
    },
    R2D: 180 / Math.PI,
    aciveRotation: false,
    containerData: null,
    modal: false,
    ShowList: false,
    CargoNoPosition:[],
    NotValidPosJson: [],
    NotPositioned: false,
    FgInvalidPosition: false,
    ContainerList: {},
    layoutCopyOne: [],
    layoutCopyTwo: [],
    layoutCopyThree: [],
    layoutCopyFour: [],
    layoutCopyFive: [],
    AuxiliarBlockJson: [],
    RecognitionZoneJson: [],
    modalPosition: false,
    positionData : {}
  }
}

/****** Yard ******/
function colNum(block){ //Fix index HeightJson

  let position = [];

  if(block?.PositionJsonAsc) 
  {
    position = block.PositionJsonAsc
  }
  //STACK DESC BAY DESC
  else if(block.FgBlockOrder && block.FgBayOrder)
  {
    position = block.HeightJson[0].PositionJsonDesc[0].PositionJsonBayDesc
  }
  //STACK ASC BAY ASC 
  else if(!block.FgBlockOrder && !block.FgBayOrder)
  {
    position = block.HeightJson[0].PositionJsonAsc[0].PositionJsonBayAsc
  }
  //STACK DESC BAY ASC
  else if(block.FgBlockOrder && !block.FgBayOrder)
  {
    position = block.HeightJson[0].PositionJsonDesc[0].PositionJsonBayAsc
  }
  //STACK ASC BAY DESC 
  else if(!block.FgBlockOrder && block.FgBayOrder)
  {
    position = block.HeightJson[0].PositionJsonAsc[0].PositionJsonBayDesc
  }
  else
  {
    position = []
  }

  if(block.HeightJson && block.HeightJson.length != 0){
    return position?.length / block?.StackQuantity ?? 0;
  } else if(block?.PositionJsonAsc){
    return block?.PositionJsonAsc?.length / block.StackQuantity;
  }
  else
    return 0;
}
function rowNum(block){
  if(block.Height){
    return block.StackQuantity;
  } else
    return 0;
}
function generateLayout(block, index) {

  let position = [];

  if(block?.PositionJsonAsc) 
  {
    position = block.PositionJsonAsc
  }
  //STACK DESC BAY DESC
  else if(block.FgBlockOrder && block.FgBayOrder)
  {
    position = block.HeightJson[index].PositionJsonDesc[0].PositionJsonBayDesc
  }
  //STACK ASC BAY ASC 
  else if(!block.FgBlockOrder && !block.FgBayOrder)
  {
    position = block.HeightJson[index].PositionJsonAsc[0].PositionJsonBayAsc
  }
  //STACK DESC BAY ASC
  else if(block.FgBlockOrder && !block.FgBayOrder)
  {
    position = block.HeightJson[index].PositionJsonDesc[0].PositionJsonBayAsc
  }
  //STACK ASC BAY DESC 
  else if(!block.FgBlockOrder && block.FgBayOrder)
  {
    position = block.HeightJson[index].PositionJsonAsc[0].PositionJsonBayDesc
  }
  else
  {
    position = []
  }

  let layout = [];
  let bays = this.colNum(block);
  
  position = block?.PositionJsonAsc ? position.map(item => { return {...item, tpa: block.tpa}}) : position;

  for (let i = 0; i < position?.length; i+=bays) 
    layout.push(position.slice(i, i+bays));
  
  return layout

}

function responsiveCol(block) {
  let colToDeck = this.colNum(block);
  let colUnderDeck = this.colNum(block);
  let colNum = colToDeck >= colUnderDeck ? colToDeck : colUnderDeck;
  ;
  return colNum;
}
function selectedPosition(item) {
  if(!this.config) {
    if(item.CargoJson && item.CargoJson.length != 0){
      this.ShowList = false;
      this.NotPositioned = false;
      this.FgInvalidPosition = false;
      this.containerData = {...item};
      this.modal = true;
    }
    else{
      if (item.YardSlotStatus === process.env.VUE_APP_YARD_SLOT_STATUS_ACTIVE) {
        this.modalPosition = true;
        this.positionData = item
      }
    }
  }
}
function ValidateTpArea(item) {
  if (!this.config&&process.env.VUE_APP_TP_YARD_AREA_RECOGNITION==item.TpYardAreaId) {
    let Recognition= this.RecognitionZoneJson.find((e)=>{return e.YardAreaId === item.YardAreaId})
    this.ShowList = true;
    this.NotPositioned = false;
    this.FgInvalidPosition = false;
    this.ContainerList = Recognition ?? {};
    this.modal = true;
  }

  if (!this.config&&process.env.VUE_APP_TP_YARD_AREA_AUXILIAR_AREA==item.TpYardAreaId) {
    let Auxiliar= this.AuxiliarBlockJson.find((e)=>{return e.YardAreaId === item.YardAreaId})
    this.ShowList = true;
    this.NotPositioned = false;
    this.FgInvalidPosition = false;
    this.ContainerList = Auxiliar ?? {};
    this.modal = true;
  }
}
function PointerArea(item){
  if (!this.config&&process.env.VUE_APP_TP_YARD_AREA_RECOGNITION==item.TpYardAreaId) {
    let Recognition= this.RecognitionZoneJson.find((e)=>{return e.YardAreaId === item.YardAreaId})
    if (Recognition?.CargoJson&&Recognition.CargoJson.some(CargoJson => CargoJson.ContainerCode==this.$store.state.yardsetting.search)) 
      return {cursor: 'pointer', background: 'repeating-linear-gradient( -45deg, #ec631b, #ec631b 2.5px, transparent 2.5px, transparent 5px)'};
    else 
      return {cursor: 'pointer'};
  }

  if (!this.config&&process.env.VUE_APP_TP_YARD_AREA_AUXILIAR_AREA==item.TpYardAreaId) {
    let Auxiliar= this.AuxiliarBlockJson?.find((e)=>{return e.YardAreaId === item.YardAreaId})
    if (Auxiliar?.CargoJson&&Auxiliar?.CargoJson.some(CargoJson => CargoJson.ContainerCode==this.$store.state.yardsetting.search)) 
      return {cursor: 'pointer', background: 'repeating-linear-gradient( -45deg, #ec631b, #ec631b 2.5px, transparent 2.5px, transparent 5px)'};
    else 
      return {cursor: 'pointer'};
  }

  return {};
  
}

function notPositionedSearch(item) {
  this.modal = true;
  this.ShowList = true;
  this.NotPositioned = true;
  this.FgInvalidPosition = false;
  this.ContainerList = {'CargoJson' : item}; 
}

function notPositionedContainers() {
  this.modal = true;
  this.ShowList = true;
  this.NotPositioned = true;
  this.FgInvalidPosition = false;
  this.ContainerList = {'CargoJson' : this.CargoNoPosition}; 
}

function InvalidPosition() {
  this.ShowList = true;
  this.NotPositioned = false;
  this.FgInvalidPosition = true;
  this.ContainerList = this.NotValidPosJson[0] ?? {CargoJson: []}; 
  this.modal = true;
}

function getCurrentLayout(div) {
  switch (div) {
    case 'AREA_1':
      return this.layoutCopyOne;
    case 'AREA_2':
      return this.layoutCopyTwo;
    case 'AREA_3':
      return this.layoutCopyThree;
    case 'AREA_4':
      return this.layoutCopyFour;
    case 'AREA_5':
      return this.layoutCopyFive;
    default:
      return [];
  }
}
function exceedRowLimit(divId, aType){
  let filterLayout = this.getCurrentLayout(divId).filter((item) => item.x == 1 || item.x == 3),
    rowsCount = 0;


  for (let index = 0; index < filterLayout.length; index++) {
      rowsCount += filterLayout[index].h;
  }

  if(aType == '0' || aType == '180'){
    rowsCount += 3;
  } else {
    rowsCount += 15;
  }

  return rowsCount > 28;
}

//Rotate 
function rotateStart(e, item) {
  let bb = e.target.parentNode.parentNode.getBoundingClientRect(),
    t = bb.top,
    l = bb.left,
    h = bb.height,
    w = bb.width,
    x=0,
    y=0;
  this.center.x = l + w / 2;
  this.center.y = t + h / 2;

  x = e.clientX - this.center.x;
  y = e.clientY - this.center.y;
  this.startAngle = this.R2D * Math.atan2(y, x);
  this.aciveRotation = true;
}
function rotateEnd(e, item) {
  this.aciveRotation = false;
  this.startAngle = 0;
  this.center.x = 0;
  this.center.y = 0;
}
function rotateMove(e, item, area) {
  if(this.aciveRotation){

    let x = e.clientX - this.center.x,
    y = e.clientY - this.center.y,
    d = this.R2D * Math.atan2(y, x);
    let rotation = d - this.startAngle;
    let newAngle = item.a + rotation;
    this.setAngle(item, newAngle, area);
  }
}
function setAngle(item, angle, area) {
  let found = -1;
  switch (area) {
    case 1:
      if(item.YardAreaId)
        found = this.layoutCopyOne.findIndex((it) => it.YardAreaId == item.YardAreaId);      
      else if(item.YardBlockId)
        found = this.layoutCopyOne.findIndex((it) => it.YardBlockId == item.YardBlockId);
      if(found != -1) {
        this.layoutCopyOne[found].a = angle;
      }
      break;
    case 2:
      if(item.YardAreaId)
        found = this.layoutCopyTwo.findIndex((it) => it.YardAreaId == item.YardAreaId);      
      else if(item.YardBlockId)
        found = this.layoutCopyTwo.findIndex((it) => it.YardBlockId == item.YardBlockId);
      if(found != -1) {
        this.layoutCopyTwo[found].a = angle;
      }
      break;
    default:
      break;
  }
}
function rotateUp(item, area, type=''){
  let newAngle = item.a - 5;
  if(type == 'block') {
    if(newAngle == -50)
      newAngle = -45;
  }
  this.setAngle(item, newAngle, area);
}
function rotateDown(item, area, type=''){
  let newAngle = item.a + 5;
  if(type == 'block') {
    if(newAngle == 50)
      newAngle = 45;
  }
  this.setAngle(item, newAngle, area);
}

function updateCopy(nw){
  let result = [];


  for (let i = 0; i < nw.length; i++) {
    if(nw[i].YardAreaId) {
      if (result.findIndex((item) => item.YardAreaId == nw[i].YardAreaId) == -1)
      if(!nw[i].i)
          nw[i].i = nw[i].YardAreaId;
        result.push(nw[i]);
    }

    if(nw[i].YardBlockId) {
      if (result.findIndex((item) => item.YardBlockId == nw[i].YardBlockId) == -1){
        if(!nw[i].i)
          nw[i].i = nw[i].YardBlockId;
        result.push(nw[i]);
      }
    }
  }

  return result;
}
function mapYardStyles() {
  if(!this.print) {
    return { width: `${this.zoom}%` };
  } else {
    return {};
  }
}

// Rotate Classes
function blockClasses(type) {
  return {
    "block": true,
    "block-90": type == 90 || type == 270 ? true : null,
    "block-180": type == 180 ? true : null,
    "block-270": type == 270 ? true : null,
  };
}
function codeClasses(type) {
  return {
    "code": true,
    "code-90": type == 90 || type == 270 ? true : null,
    "code-180": type == 180 ? true : null,
    "code-270": type == 270 ? true : null,
  };
}
function lineClasses(type) {
  return {
    "line": true,
    'border-black': this.print ? true :  null,
    "line-90": type == 90 || type == 270? true : null,
  };
}
function gridClasses(type) {
  return {
    "grid": true,
    "grid-90": type == 90 || type == 270? true : null,
  };
}
function controlsOptionClasses(type) {
  return {
    "controls-option": type == 0 ? true : null,
    "controls-option-90": type == 90 ? true : null,
    "controls-option-180": type == 180 ? true : null,
    "controls-option-270": type == 270 ? true : null,
  };
}
function controlClasses(type, remove = 0) {
  return {
    "control": true,
    "remove": remove != 0 ? true : null,
    "control-90": type == 90 ? true : null,
  };
}

function rowStyles() {
  if(this.print) return;

  return {
    width: '100%',
  }
}

export default {
  data,
  methods: {
    colNum,
    rowNum,
    generateLayout,
    setAngle,
    rotateUp,
    rotateDown,
    selectedPosition,
    blockClasses,
    codeClasses,
    lineClasses,
    gridClasses,
    controlsOptionClasses,
    controlClasses,
    updateCopy,
    getCurrentLayout,
    exceedRowLimit,
    ValidateTpArea,
    notPositionedContainers,
    InvalidPosition,
    notPositionedSearch,
    PointerArea,
  },
  computed: {
    rowStyles,
    mapYardStyles,
  }
}
