<template>
  <div :class="bayContainerClasses">
    <!-- Numbers Top -->
    <div 
      v-if="showNumbers && !showNumbersBottom"
      :class="rowClasses"
      :style="rowStyles"
    >
      <!-- <div
        class="col-item item-number-corner"
        :style="itemStyles"
      ></div> -->
      <div
        v-for="number in ['', ...colNumbers]"
        :key="number.index"
        :class="numbersColClasses"
        :style="itemStyles"
      >
        <img :src="getCharacterSvg(number.CodPosition)" alt=""
          class="item-number-icon"
          :style="{ width: `${numberSize}%`, height: `${numberSize}%` }"
          v-if="number != ''"
        >
      </div>
      <!-- <div
        class="col-item item-number-rows item-not-exist"
        :style="itemStyles"
      ></div> -->
    </div>

    <!-- Bay Square -->
    <div 
      :class="rowClasses"
      :style="rowStyles"
      v-for="(rows, index) in layoutItems" :key="index"
    >
      <!-- Numbers Col Left -->
      <div
        class="col-item item-number-rows"
        :class="numbersRowClasses"
        :style="itemStyles"
        v-if="showNumbers"
      >
        <img :src="getCharacterSvg(rows[0].StackCode[1] === '0' ?  rows[0].StackCode?.substr(2,1) : rows[0].StackCode?.substr(1,2))" alt=""
          class="item-number-icon"
          :style="{ width: `${numberSize}%`, height: `${numberSize}%` }"
        >
      </div> 

      <!-- Squares -->
      <div
        v-for="(col, index) in rows"
        :key="col.YardSlotId"
        :class="itemClasses(col)"
        :style="containerItemStyle(col)"
        @click.prevent="selectPosition(col)"
      >
        <!-- Tooltip -->
        <span
          class="sigeop-tooltip"
          v-if="tooltip"
        >
          {{ tooltipCode(col) }}
        </span>

        <!-- Colores de fondo por nivel ocupados. -->
        <div
          class="bg-position"
          :style="{ background: colorByViewType(col) }"
          v-if="col.CargoJson && col.CargoJson.length != 0 && viewType != ''"
        ></div>

        <!-- Colores segun las Preferencias seleccionadas -->
        <div
          class="bg-position"
          :style="{ background: colorPreference(col) }"
          v-if="allColorPreferences.length != 0 && col.CargoJson && col.CargoJson.length != 0"
        ></div>

        <!-- Contenedor buscado. -->
        <div
          class="bg-position border-search"
          v-if="hasSearchCode(col)"
        ></div>

        <!-- Cubir adyacente -->
        <div :class="covers(1, col)" :style="sideColors(col)" v-if="checkAdjacent('right', col)&&(col.tpa==0||col.tpa==180)"></div>
        <!--<div :class="covers(2, col)" :style="sideColors(col)" v-if="checkAdjacent('left', col)&&(col.tpa==0||col.tpa==180)"></div>-->
        <div :class="covers(3, col)" :style="sideColors(col)" v-if="checkAdjacent('right', col)&&(col.tpa==90||col.tpa==270)"></div>
        <div :class="covers(4, col)" :style="sideColors(col)" v-if="checkAdjacent('left', col)&&(col.tpa==90||col.tpa==270)"></div>

        <!-- Iconos en Bay del Buque -->
        <div :class="statusIcon(col)" v-if="showStatus"></div>
        <div class="imdg" :style="imdgStyles(col)" v-if="cargoIcon(1, col) && !checkAdjacent('left', col)"></div>
        <div class="oversize-top" v-if="cargoIcon(2, col)"></div>
        <div class="oversize-right" v-if="cargoIcon(3, col)"></div>
        <div class="oversize-left" v-if="cargoIcon(4, col)"></div>
        <div class="oversize-front" v-if="cargoIcon(5, col)"></div>
        <div class="oversize-back" v-if="cargoIcon(6, col)"></div>
        

        <!-- Iconos segun las Preferencias seleccionadas -->
        <div :class="iconPreference(col)" v-if="!imgPreference(col) && !checkAdjacent('left', col)"></div>
        <img src="/img/iconos/Standard.svg" class="st-estandar" v-if="imgPreference(col) && !checkAdjacent('left', col)">

        <!-- Iconos obligatorios -->
        <img src="/img/iconos/OccupiedBy40.svg" :style="sobreDimentionStyles(col)" class="occupied-by-40" v-if="validMandatory(1, col)  && !checkAdjacent('left', col)">
        <div class="high-cube" v-if="validMandatory(2, col)&&(col.tpa==0||col.tpa==180) && !checkAdjacent('left', col)"></div>
        <div class="high-cube-90-270" v-if="validMandatory(2, col)&&(col.tpa==90||col.tpa==270) && !checkAdjacent('left', col)"></div>
        <div class="reefer" v-if="validMandatory(3, col)&& !checkAdjacent('left', col)"></div>

         
      </div>

      <!-- Invisible Col Right -->
      <!-- <div
        class="col-item item-number-rows item-not-exist"
        :style="itemStyles"
        v-if="showNumbers"
      >
      </div> -->
    </div>
    <!-- Numbers Bottom -->
    <div v-if="showNumbers && showNumbersBottom" class="row-item">
      <div
        class="col-item item-number-corner"
        :style="itemStyles"
      ></div>
      <div
        v-for="number in colNumbers"
        :key="number.CodPosition"
        class="col-item item-number-cols"
        :class="numbersColClasses"
        :style="itemStyles"
      >
        <img :src="getCharacterSvg(number.CodRowPos)" alt=""
          class="item-number-icon"
          :style="{ width: `${numberSize}%`, height: `${numberSize}%` }"
        >
      </div>
      <div
        class="col-item item-number-rows item-not-exist"
        :style="itemStyles"
      ></div>
    </div>
  </div>
</template>

<script>
import { GenerateSvgPlaceholder, slugify } from '@/_helpers/funciones';

//Data
function data() {
  return {
    layout: [],
    currentPosition: null,
  };
}

//Created
function created() {
  
}

//Metodos:
function selectPosition(item) {
  this.$emit('selected-position', item);
}
function itemClasses(item) {
  return {
    "col-item": this.angleType == 0 || this.angleType == 180 ? true : null,
    "col-item-90": this.angleType == 90 || this.angleType == 270 ? true : null,
    "item-not-exist":
      item.YardSlotStatus == "4489952A-9675-46B4-A759-942CE9D0A095" && this.showStatus
        ? true : null,
    "item-inactive":
      item.YardSlotStatus == "INACTIVE" && this.showStatus ? true : null,
    "item-disabled":
      item.YardSlotStatus == "A889E5F6-57BA-4FBA-B7D3-2AD8BD8A0D17" && this.showStatus ? true : null,
    "cursor-print": this.print ? true : null,
    // "border-search": item.CargoJson && item.CargoJson.length > 0 ?
    //     this.$store.state.yardsetting.search == item.CargoJson[0].ContainerCode ? true : null
    //   : null,
  };
}

function covers(type, item) {
  return {
          "cover-right": type == 1 ? true : null,
          "cover-left": type == 2 ? true : null,
          "cover-bottom": type == 3 ? true : null,
          "cover-top": type == 4 ? true : null,
  };
}

function backgroundCovers(item) {
  let background = {background : 'transparent'}

  if( this.viewType == 'level' && item.CargoJson && item.CargoJson.length != 0)
    background = { background : '#EC631B' }

  if( this.viewType == 'current' && item.CargoJson && item.CargoJson.length != 0)
    background = { background : item.HeightColor }
  return background;
}

function getCharacterSvg(character) {
  return GenerateSvgPlaceholder({
    width: 20,
    height: 20,
    bgColor: 'transparent',
    textColor: this.print ? '#000' : '#fff',
    fontSize: 21,
    fontFamilty: 'Roboto',
    text: `${character}`
  });
}
function tooltipCode(item) {
  return item.SlotCode ? item.SlotCode : '';
}
function containerItemStyle(item) {
  return this.itemStyles;
}
function statusIcon(item) {
  let yardStatus = item.YardSlotStatus ? item.YardSlotStatus : item.YardSlotStatusId;
  switch (yardStatus) {
    case 'D536C308-D018-4C47-B05F-BB402201ADBA': //Activo
      return '';
    case 'A889E5F6-57BA-4FBA-B7D3-2AD8BD8A0D17': //Disabled
      return 'disabled-position';
    case 'INACTIVE':
      return '';
    case '4489952A-9675-46B4-A759-942CE9D0A095': // Not exist
      return '';
    case '75867AFE-6EC2-46E1-96FD-C493484F10D2': //Reefer plug
      return 'reefer-plug-status';
    case '248C75E3-1B75-40ED-9505-8640F66498E9': //Carga peligrosa habilitada
      return 'hazard-available';
    case '81EF40D6-CA32-4328-9F50-4C15CA4D39F5': //Carga peligrosa restringida
      return 'hazard-restriction';
    case 'BF831FE8-DA61-4961-80AD-9350138CA40F': //Special container
      return 'special-container';
    default:
      return '';
  }
}
function cargoIcon(condition, item) {
  if(!this.showStatus)
    return false;

  if(this.config)
    return;
  /*
  let left = false, right = false; 
  if(item.tpa == 0 || item.tpa == 90 || item.tpa == 180){
    if( (rows?.length > index) && (item?.CargoJson?.length>0 && rows[index+1]?.CargoJson?.length>0) && (item?.CargoJson[0]?.ContainerCode == rows[index+1]?.CargoJson[0]?.ContainerCode))
        right = true;
    
    if( (index > 0) && (item?.CargoJson?.length>0 && rows[index-1]?.CargoJson?.length>0) && (item?.CargoJson[0]?.ContainerCode == rows[index-1]?.CargoJson[0]?.ContainerCode))
        left = true;
  }*/

  if(item.tpa == 0 || item.tpa == 180){
    switch (condition) {
      case 1:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].ImdgClassId;
      case 2:
        return false;
      case 3:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimRight && !this.checkAdjacent('right', item);
      case 4:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimLeft && !this.checkAdjacent('left', item);
      case 5:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimFront;
      case 6:
        return false;
      default:
        return false;
    }
  }

  if(item.tpa == 90){
    switch (condition) {
      case 1:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].ImdgClassId;
      case 2:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimLeft && !this.checkAdjacent('left', item);   
      case 3:
        return false; 
      case 4:
        return false; 
      case 5:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimFront; 
      case 6:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimRight && !this.checkAdjacent('right', item); 
      default:
        return false;
    }
  }

  /*
    checkAdjacent('right', col)
    checkAdjacent('left', col)
  */
  
  if(item.tpa == 270){
    switch (condition) {
      case 1:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].ImdgClassId;
      case 2:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimRight && !this.checkAdjacent('left', item);   
      case 3:
        return false; 
      case 4:
        return false; 
      case 5:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimFront; 
      case 6:
        return item.CargoJson && item.CargoJson.length > 0 && item.CargoJson[0].DimLeft && !this.checkAdjacent('right', item); 
      default:
        return false;
    }
  }
}
function colorByViewType(item) {
  let color = 'transparent';

  if(this.viewType == 'level' && item.CargoJson && item.CargoJson.length != 0)
    return '#EC631B';

  if(this.viewType == 'current' && item.CargoJson && item.CargoJson.length != 0)
    return item.HeightColor;

  return color;
}
function hasSearchCode(item) {
  return item.CargoJson && item.CargoJson.length > 0 ?
        this.$store.state.yardsetting.search == item.CargoJson[0].ContainerCode ? true : false
      : false;
}
function colorPreference(item) {
  let color = 'transparent';
  let arr = this.allColorPreferences;


  if(arr.length == 0 || item.CargoJson.length == 0 || item.CargoJson == null)
    return color;

  for (let index = 0; index < arr.length; index++) {
    let element = arr[index];
    if(element[element.PreferenceElement] == item.CargoJson[0][element.PreferenceElement] ) {
      color = element.Color;
      break;
    } 
  }

  let consigneeArr = arr.filter((bl) => bl.PreferenceElement == 'ConsigneeId');
  if(consigneeArr.length != 0){
    if(consigneeArr.length == 1){
      let found = item.CargoJson[0].BlJson.findIndex((el) => el.ConsigneeId == consigneeArr[0].ConsigneeId);
      if(found != -1)
        color = consigneeArr[0].Color;
    } else {
      let filterBlJson = item.CargoJson[0].BlJson.filter((v,i,a)=>a.findIndex(v2=>(v2.ConsigneeId===v.ConsigneeId))===i);
      let increment = 100 / filterBlJson.length,
        count = increment,
        gradient = 'linear-gradient(to right, ';
      for (let index = 0; index < filterBlJson.length; index++) {
        let element = consigneeArr.findIndex((el) => el.ConsigneeId == filterBlJson[index].ConsigneeId);
        if(element != -1) {
          if(index == 0)
            gradient += `${consigneeArr[element].Color} 0 ${count}%`;
          else
            gradient += `, ${consigneeArr[element].Color} 0 ${count}%`;

          count += increment;
        } 
      }
      color = gradient+')';
    }
  }

  let brokerArr = arr.filter((bl) => bl.PreferenceElement == 'CustomBrokerId');
  if(brokerArr.length != 0){
    if(brokerArr.length == 1){
      let found = item.CargoJson[0].BlJson.findIndex((el) => el.CustomBrokerId == brokerArr[0].CustomBrokerId);
      if(found != -1)
        color = brokerArr[0].Color;
    } else {
      let filterBlJson = item.CargoJson[0].BlJson.filter((v,i,a)=>a.findIndex(v2=>(v2.CustomBrokerId===v.CustomBrokerId))===i);
      let increment = 100 / filterBlJson.length,
        count = increment,
        gradient = 'linear-gradient(to right, ';
      for (let index = 0; index < filterBlJson.length; index++) {
        let element = brokerArr.findIndex((el) => el.CustomBrokerId == filterBlJson[index].CustomBrokerId);
        if(element != -1) {
          if(index == 0)
            gradient += `${brokerArr[element].Color} 0 ${count}%`;
          else
            gradient += `, ${brokerArr[element].Color} 0 ${count}%`;

          count += increment;
        } 
      }
      color = gradient+')';
    }
  }

  return color;
}
function iconPreference(item) {
  let name = null;
  let arr = this.preferences;

  if(arr.length == 0 || item.CargoJson.length == 0 || item.CargoJson == null)
    return name;

  for (let index = 0; index < arr.length; index++) {
    let element = arr[index];
    if(element[element.PreferenceElement] == item.CargoJson[0][element.PreferenceElement] ) {
      name = slugify(element.Name);
      break;
    } 
  }

  return name;
}
function imgPreference(item) {
  let imgs = ['st-estandar', 'st-standard'];
  let arr = this.preferences;

  if(arr.length == 0 || item.CargoJson.length == 0 || item.CargoJson == null)
    return false;

  for (let index = 0; index < arr.length; index++) {
    let element = arr[index];
    if(element[element.PreferenceElement] == item.CargoJson[0][element.PreferenceElement] && imgs.includes(slugify(element.Name))) {
      return true;
    } 
  }

  return false;
}
function validMandatory(condition, item) {
  if(!this.viewType)
    return false;

  switch (condition) {
    case 1:
      return item.CargoJson && item.CargoJson.length != 0 && item.CargoJson[0].SizeId == "857C1454-2883-4C41-8AF6-66D278DC4435";
    case 2:
      return item.CargoJson && item.CargoJson.length != 0 && (item.CargoJson[0].TpCargoId === '6C6CE673-3797-4D11-839B-06C228D51CEF' || item.CargoJson[0].TpCargoId === '40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5');
    case 3:
      return (item.CargoJson && item.CargoJson.length != 0 && item.CargoJson[0].TpCargoId === '40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5');
    default:
      return false;
  }
}
function checkAdjacent(dir, item) {
  //if(this.viewType == 'current') return false;
  switch (dir) {
    case 'right':
      return item.FgRight == 1;
    case 'left':
      return item.FgLeft == 1;
    default:
      return false;
  }
}
function sideColors(item) {
  //if(this.viewType == 'current') return {};

  if(this.allColorPreferences.length != 0 && item.CargoJson && item.CargoJson.length != 0){
    if(this.hasSearchCode(item)){
      let color = this.colorPreference(item) ;


      if(color.search('linear-gradient') == -1){
        return {
          background: `repeating-linear-gradient( -45deg, #000, #000 2.5px, ${color} 2.5px, ${color} 5px )`
        };
      } else {
        if(item.FgLeft) {
          let leftColor = color.substr(color.search('#'), 7);
          return {
            background: `repeating-linear-gradient( -45deg, #000, #000 2.5px, ${leftColor} 2.5px, ${leftColor} 5px )`
          };
        }

        if(item.FgRight) {
          let rightColor = color.substr(color.lastIndexOf('#'), 7);
          return {
            background: `repeating-linear-gradient( -45deg, #000, #000 2.5px, ${rightColor} 2.5px, ${rightColor} 5px )`
          };
        }
      }
    } else{
      let color = this.colorPreference(item);

      if(color.search('linear-gradient') == -1){
        return { background: color };
      } else {
        if(item.FgLeft == 1) {
          let leftColor = color.substr(color.search('#'), 7);
          return { background: leftColor };
        }

        if(item.FgRight == 1) {
          let rightColor = color.substr(color.lastIndexOf('#'), 7);
          return { background: rightColor };
        }
      }
    }
  } else {
    if(this.hasSearchCode(item)){
      return {
        'background': `repeating-linear-gradient( -45deg, #000, #000 2.5px, ${ this.colorByViewType(item)} 2.5px, ${ this.colorByViewType(item)} 5px )`,
       
      };
    }
    else
      return {
        'background-color': this.colorByViewType(item),
        'transition': 'background-color 0.5s linear'
      };
  }
}

//Computed
function layoutItems() {
  return this.items;
}
function colNumbers() {
  let cols = this.col;
  let arr = [];
  let data = this.layoutItems

  data[0].map((rows, index)=>{
    arr.push({
      index: index, 
      CodPosition: rows.BayCode[1] === '0' ?  rows.BayCode?.substr(2,1) : rows.BayCode?.substr(1,2),
    });
  })
  
  return arr || [];
}
function itemStyles() {
  if(this.responsive && this.maxCol > this.col) {
    let quantity = this.showNumbers ? (this.maxCol + 2) : this.maxCol;
    return { 
      width: `calc( calc(100% / ${quantity}) - ${this.margin*2}px )`,
      height: this.itemHeight == 0 ? '100%' : `${this.itemHeight}px`, //`${this.itemHeight}px`
      // maxWidth: this.maxSize > 40 ? `${this.maxSize}px` : '40px',
      margin: `${this.margin}px`,
    };
  }

  if(this.responsive){
    let quantity = this.showNumbers && this.lateralNumbers ? (this.col + 2) : this.col;
    quantity = quantity;
    
    if(this.angleType == 0 || this.angleType == 180) {
      return {
        width: `calc( calc(100% / ${quantity}) - ${this.margin*2}px )`,
        height: this.itemHeight == 0 ? '100%' : `${this.itemHeight}px`,
        // maxWidth: this.maxSize > 40 ? `${this.maxSize}px` : '40px',
        margin: `${this.margin}px`,
      };
    } else if(this.angleType == 90 || this.angleType == 270) {
      return {
        width: '100%',
        height: `calc( calc(100% / ${quantity+1}) - ${this.margin*2}px )`,
      };
    }
  }

  if(this.angleType == 0 || this.angleType == 180) {
    return {
      width: `${this.itemWidth}px`, height: this.itemHeight == 0 ? '100%' : `${this.itemHeight}px`,
      // maxWidth: this.maxSize > 40 ? `${this.maxSize}px` : '40px',
      margin: `${this.margin}px`,
    };
  } else if(this.angleType == 90 || this.angleType == 270) {
    return {
      width: '100%',
      height: `calc( calc(100% / ${this.col}) - 5px )`,
    };
  }
}



function imdgStyles(col) {

  if(this.angleType == 0 || this.angleType == 180) {
    let quantity = (parseInt((document.getElementById(this.block?.i)?.style?.height)?.replace('px', '')) - (this.block.StackQuantity+1)) / (this.block.StackQuantity+1);
    let style = (checkAdjacent('left', col) || checkAdjacent('right', col)) ? {left: '100%'} : {}

    if(this.allColorPreferences.length != 0)
      style = {...style, border: '2px solid red'}
    else
      style = {...style, border: '2px solid red'}

    return {
      ...style,
      width: `${quantity/2}px`,
      height:`${quantity/2}px`,

    };
  } else if(this.angleType == 90 || this.angleType == 270) {
      let quantity = (parseInt((document.getElementById(this.block?.i)?.style?.width)?.replace('px', '')) - (this.block.BayQuantity+1)) / (this.block.BayQuantity+1);
      let style = (checkAdjacent('left', col) || checkAdjacent('right', col)) ? {top: '100%'} : {}

      if(this.allColorPreferences.length != 0)
        style = {...style, border: '2px solid red'}
      else
        style = {...style, border: '2px solid red'}

      return {
        ...style,
        width: `${quantity/2}px`,
        height:`${quantity/2}px`,
      };
    }
}

function sobreDimentionStyles(col) {
  if(checkAdjacent('left', col) || checkAdjacent('right', col)) 
    if(col.tpa==0||col.tpa==180)
      return {
        'left': '100%',
        'z-index': '999'
      }
    else
      return {
        'top': '100%',
        'z-index': '999'
      }
  
  
  return false;
}

function numbersColClasses() {
  return {
    "col-item item-number-cols": this.angleType == 0 || this.angleType == 180 ? true : null,
    "col-item-90 item-number-vertical-90": this.angleType == 90 || this.angleType == 270? true : null,
    "item-number-vertical-270": this.angleType == 270? true : null,
    "item-number-cols-dashed": this.showDashedNumbers ? true : null,
  };
}
function numbersRowClasses() {
  return {
    "item-number-rows-dashed": this.showDashedNumbers ? true : null,
    "col-item item-number-cols": this.angleType == 0 || this.angleType == 180 ? true : null,
    "col-item-90 item-number-vertical-90": this.angleType == 90 || this.angleType == 270? true : null,
    "item-number-vertical-270": this.angleType == 270? true : null,
  };
}

// Rotation Classes/Styles
function bayContainerClasses() {
  return {
    "bay-container": this.angleType == 0 || this.angleType == 180 ? true : null,
    "bay-container-vertical-90": this.angleType == 90 || this.angleType == 270? true : null,
    "bay-container-vertical-270": this.angleType == 270? true : null,
    'border-container': this.border ? true: null,
  };
}
function rowStyles() {
  if(this.angleType == 0 || this.angleType == 180){
    return {
      height:  this.itemHeight == 0 ? `calc( calc(100% / ${this.row}) - ${this.margin*2}px )` : null,
    };
  } else if(this.angleType == 90 || this.angleType == 270) {
    return {
      width: `calc( calc(100% / ${this.row}) -  1px )`,
    };
  }
}
function rowClasses() {
  return {
    "row-item": this.angleType == 0 || this.angleType == 180 ? true : null,
    "vertical-item-90": this.angleType == 90 || this.angleType == 270? true : null,
    "vertical-item-270": this.angleType == 270? true : null,
  };
}
function allColorPreferences() {
  return this.preferences.filter((item) => item.FgColor == true);
}

export default {
  name: "yard-grid",
  props: {
    //Generar Matriz
    col: {
      type: Number,
      default: 12,
      require: true,
    },
    row: {
      type: Number,
      default: 12,
      require: true,
    },
    items: {
      type: Array,
      default: () => []
    },
    itemWidth: {
      type: Number,
      default: 60,
    },
    itemHeight: {
      type: Number,
      default: 0,
    },
    maxSize: {
      type: Number,
      default: 60
    },
    margin: {
      type: Number,
      default: 2.5
    },
    disabledSelect: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    //Para activar responsive.
    responsive: {
      type: Boolean,
      default: true,
    },
    maxCol: {
      type: Number,
      default: 0
    },
    //Para mostrar los numeros de la fila y columna
    showNumbers: {
      type: Boolean,
      default: false,
    },
    lateralNumbers: {
      type: Boolean,
      default: false,
    },
    showNumbersBottom: {
      type: Boolean,
      default: false,
    },
    showDashedNumbers: {
      type: Boolean,
      default: false,
    },
    numberSize: {
      type: Number,
      default: 35,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    //Container
    border: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: '',
    },
    angleType: {
      type: Number,
      default: 0,
    },
    preferences: {
      type: Array,
      default: () => []
    },
    block: {
      type: Object,
      default: () => {}
    },
  },
  components: {},
  data,
  created,
  methods: {
    selectPosition,
    itemClasses,
    getCharacterSvg,
    tooltipCode,
    containerItemStyle,
    statusIcon,
    cargoIcon,
    colorByViewType,
    hasSearchCode,
    colorPreference,
    iconPreference,
    imgPreference,
    validMandatory,
    checkAdjacent,
    sideColors,
    covers,
    backgroundCovers,
    imdgStyles,
    sobreDimentionStyles,
  },
  computed: {
    layoutItems,
    colNumbers,
    itemStyles,
    numbersColClasses,
    numbersRowClasses,
    bayContainerClasses,
    rowStyles,
    rowClasses,
    allColorPreferences,
  },
  watch: {
  },
};
</script>

<style scoped>
.cursor-print {
  cursor: default;
}
.bay-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row-item {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}

.col-item {
  position: relative;
  line-height: 1;
  /* max-width: 40px; */
  background-color: #fff;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin: 2.5px;
  border: 1px solid #000;
  border-radius: 2px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.col-item-responsive::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.item-number-icon { /* Center and .svg */
  position: absolute;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.item-number-cols {
  margin: 0 2.5px;
  border: none;
  cursor: default;
  background-color: transparent;
}
.item-number-rows {
  margin: 2.5px 0;
  border: none;
  cursor: default;
}
.item-number-corner {
  margin: 0;
  border: none;
  cursor: default;
}
.item-number-cols-dashed {
  border-left: 1px dashed #322f32;
  border-right: 1px dashed #322f32;
}
.item-number-rows-dashed {
  border-top: 1px dashed #322f32;
  border-bottom: 1px dashed #322f32;
}

/* Iconos en Bay Buque */
.item-not-exist {
  background: transparent;
  border: none;
  color: transparent;
}
.item-disabled {
  background: #9e9e9e;
  border-color: #9e9e9e;
  color: #fff;
}
.item-plug {
  background: lightcoral;
  border-color: lightcoral;
  color: #fff;
}
.item-inactive {
  background: #eeeded;
  border-color: #eeeded;
}

.bg-gris {
  background-color: #aaaaaa !important;
}
/* Clases para cuando se esta eliminando un contenedor del bay */
.no-pointer {
  cursor: default;
}

/* Rotaciones */
.bay-container-vertical-90 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
  }
  .vertical-item-90 {
    height: 100%;
  }
  
  .col-item-90 {
    margin: 1px 0;
    width: 100%;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 2px;
    position: relative;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }

  .item-number-vertical-90 {
    transform: rotate(90deg);
    border: none;
    cursor: default;
    background-color: transparent;
  }

  .bay-container-vertical-270 {
    flex-direction: row !important;
  }
  .vertical-item-90 {
    flex-direction: column-reverse !important;
  }
  .item-number-vertical-270 {
    transform: rotate(270deg) !important;
  }

/* Positioning */
.corner-top-icon { /* Right Top corner .svg */
  position: absolute;
  width: 30%;
  height: 30%;
  top: 0;
  right: 0;
}
.center-icon { /* Center a.svg */

  position: absolute;
  width: 45%;
  height: 45%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.bg-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  transition: background-color .5s ease-in-out;
}

/* Load View */
.bg-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
}
.bg-position-select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  transition: background-color .1.7s ease-in-out;
}
.bg-position-select.not-allowed {
  background-color: #ec631d;
}
.bg-position-select.allowed {
  background-color: #00e676;
}
.bg-position-select.invalid {
  background-image: url('/img/iconos/ban-solid.svg');
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -moz-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  -o-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}
.bg-position-select.asignada,
.position-asign {
  border: 3px solid #3f51b5;
}

/* Tooltip */
.sigeop-tooltip {
  visibility: hidden;
  /* font-size: small; */
  text-align: center;
  color: #fff;
  background-color: #000015;
  border-radius: .25rem;

  padding: .25rem .5rem;
  margin-bottom: 7px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 100;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.sigeop-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: calc(50% - 5px);
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.col-item:hover .sigeop-tooltip, .col-item-90:hover .sigeop-tooltip {
  visibility: visible;
}
.tilt-animation span.sigeop-tooltip {
  opacity: 1;
}

/* Iconos en Bay Buque */
.item-not-exist {
  background: transparent;
  border: none;
  color: transparent;
}
.item-disabled {
  background: #9e9e9e;
  border-color: #9e9e9e;
  color: #fff;
}
.item-inactive {
  background: #eeeded;
  border-color: #eeeded;
}

/* Para showStatus */
.disabled-position {
  background-image: url('/img/yards/blocks/DISABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reefer-plug-status {
  background-image: url('/img/yards/blocks/RIFEL-PLUG-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-available {
  background-image: url('/img/yards/blocks/HAZARD-AVAILABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 0;
  right: 0;
}
.hazard-restriction {
  background-image: url('/img/yards/blocks/HAZARD-RESTRINGED-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 0;
  right: 0;
}
.special-container {
  background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.border-search {
  /* border: 1px solid #ec631b; */
  background: repeating-linear-gradient( -45deg, #000, #000 2.5px, transparent 2.5px, transparent 5px );
}

.oversize-top {
  width: 100%;
  height: 50%;
  border: 1px solid #555;
  background: #555;
  -moz-border-radius: 100px 100px 0 0;
  -webkit-border-radius: 100px 100px 0 0;
  border-radius: 100px 100px 0 0;
  position: absolute;
  top: -50%;
  z-index: 1;
}
.oversize-right {
  width: 50%;
  height: 100%;
  border: 1px solid #555;
  background: #555;
  border-radius: 0 100px 100px 0;
  position: absolute;
  right: -50%;
  z-index: 1;
}
.oversize-left {
  width: 50%;
  height: 100%;
  border: 1px solid #555;
  background: #555;
  border-radius: 100px 0 0 100px;
  position: absolute;
  left: -50%;
  z-index: 1;
}
.oversize-back {
  width: 100%; 
  height: 50%; 
  border: 1px solid #555;
  background-color: #555;
  -moz-border-radius: 0 0 100px 100px;
  -webkit-border-radius:  0 0 100px 100px;
  border-radius: 0 0 100px 100px;
  position: absolute;
  bottom: -50%;
  z-index: 1;
}
.oversize-front {
  width: 100%; 
  height: 20%; 
  border: 1px solid #555;
  background-color: #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  top: 0;
}
.imdg {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid white;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 999;
}

.border-container {
  border: 1px solid #bbbbbb;
  border-radius: 2px;
}
.high-cube-90-270{
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 0 100%;
  -webkit-border-radius: 0 0 0 100%;
  border-radius: 0 0 0 100%;
  border-top: none;
  border-right: none;
  position: absolute;
  top: 0;
  left: 60%;
}
.high-cube,
.hc-high-cube {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 100% 0;
  -webkit-border-radius: 0 0 100% 0;
  border-radius: 0 0 100% 0;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.high-cube-45 {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 100% 0;
  -webkit-border-radius: 0 0 100% 0;
  border-radius: 0 0 100% 0;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #373737;
}
.reefer-high-cube {
  background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
  background-position: left top, right bottom;
  background-size: 40% 40%, 40% 40%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height:100%;
}
.reefer {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 100% 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 100% 0 0 0;
  border-bottom: none;
  border-right: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
.flat-rack,
.ft-flat-rack {
  width: 30%;
  height: 30%;
  border: 1px solid #555;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.plataforma,
.pl-plataforma {
  width: 30%;
  height: 30%;
  border: 1px solid #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
.tanque,
.tk-tanque {
  width: 20%;
  height: 20%;
  border: 1px solid #555;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 2px 2px;
}
.empty {
  /* background-image: url('/img/iconos/Empty.svg'); */
  position: absolute;
  width: 25%;
  height: 25%;
  top: 4%;
  left: 75%;
}
.open-top,
.ot-open-top {
  position: absolute;
  width: 100%;
  height: 35%;
  border: 1px solid #555;
  border-top: none;
  border-right: none;
  border-left: none;
  top: 0;
}
.standard,
.st-estandar,
.st-standard {
  /* background-image: url('/img/iconos/Standard.svg'); */
  position: absolute;
  width: 35%;
  height: 35%;
  top: 0;
  left: 0;
  /* margin-top: 2px; */
}
.occupied-by-40 {
  /* background-image: url('/img/iconos/OccupiedBy40.svg'); */
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.occupied-by-45 {
  /* background-image: url('/img/iconos/Plus45_1.svg'); */
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.occupied-oversize {
  /* background-image: url('/img/iconos/OccupiedOversize.svg'); */
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.restow {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #555;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 0;
  /* margin: 0 0 2px 2px; */
}


.cover-right {
  right: -30%;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.cover-left {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 1;
}
.cover-top {
  width: 100%;
  height: 50%;
  position: absolute;
  top: -50%;
  z-index: 1;
}
.cover-bottom {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: -50%;
  z-index: 1;
}
</style>